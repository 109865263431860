import http from "@/http-common";

const getAll = async () => {
  return await http
    .get("/staff/courtesy-emitter")
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getFromParty = async (partyId) => {
  return await http
    .get(`/staff/courtesy-emitter/${partyId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const emit = async (partyId, courtesyEmitterId, user) => {
  return await http
    .post(`/staff/courtesy-emitter/${partyId}/${courtesyEmitterId}`, {
      user,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getAll,
  getFromParty,
  emit,
};
