<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close()"
    scrollable
    :persistent="loading"
    :fullscreen="tickets && tickets.length > 1"
    max-width="400px"
    content-class="rounded-lg"
  >
    <v-card :loading="loading" rounded="lg" dark>
      <template v-if="!tickets || tickets.length === 0">
        <v-tabs v-model="type" grow>
          <v-tab tab-value="document">Documento</v-tab>
          <v-tab tab-value="id">#ID</v-tab>
        </v-tabs>
        <v-card-text class="pt-6 pb-0" style="overflow: hidden">
          <h5 class="text-center mb-4">
            Buscar ingresso por {{ type === "document" ? "CPF" : "ID" }}
          </h5>
          <p v-if="type === 'document'" class="text-center">
            Informe o CPF do proprietário do ingresso
          </p>
          <p v-else class="text-center">
            Informe o ID do ingresso ou do pagamento
          </p>
          <v-form v-model="valid" @submit.prevent="findByCpf">
            <v-text-field-simplemask
              v-if="type === 'document'"
              v-model="searchValue"
              label="CPF"
              v-bind:properties="{
                autofocus: true,
                prefix: '',
                suffix: '',
                outlined: true,
                placeholder: '999.999.999-99',
                type: 'tel',
                rules: cpfRules,
                disabled: loading,
              }"
              v-bind:options="{
                inputMask: '###.###.###-##',
                outputMask: '###.###.###-##',
                empty: '',
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
              }"
            />
            <v-text-field
              v-else
              v-model="searchValue"
              label="ID do ingresso ou pagamento"
              autofocus
              outlined
              prefix="#"
              placeholder="ABC12345"
              max-length="8"
              :counter="8"
              :rules="[
                (v) => !!v || 'ID é obrigatório',
                (v) => v.length > 6 || 'ID inválido',
              ]"
            />
            <v-alert v-if="error" type="error" dense>
              {{ error }}
            </v-alert>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-btn :disabled="loading" @click="close()" text> Cancelar </v-btn>
          <v-spacer />
          <v-btn
            :disabled="loading || !valid"
            @click="findByCpf"
            color="primary"
          >
            Buscar
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-title class="d-flex flex-column align-center gap-4 text-center">
          <base-avatar
            v-if="tickets[0].Owner.photo"
            :seed="tickets[0].Owner.id"
            :size="150"
            :src="tickets[0].Owner.photo"
            class="mb-1"
          />
          <v-icon v-else size="120">mdi-account</v-icon>
          <div>
            <h4 class="mb-0">{{ tickets[0].Owner.name }}</h4>
            <h5 class="mb-0">{{ tickets[0].Owner.document }}</h5>
          </div>
          <h5 class="text-center mb-0">Ingressos encontrados</h5>
        </v-card-title>
        <v-card-text class="pt-6 pb-0">
          <div class="d-flex flex-column gap-2">
            <v-alert
              v-for="(ticket, index) in tickets"
              :key="index"
              :color="statusDic[ticket.status].color"
              :icon="statusDic[ticket.status].icon"
              class="mb-0"
            >
              <div>
                <span class="text-overline lh-1 d-block">
                  #{{ ticket.id | shortId }}
                </span>
                <span
                  v-if="ticket.TicketBlock.TicketGroup.Party"
                  class="text-overline lh-1"
                >
                  {{ ticket.TicketBlock.TicketGroup.Party.name }}
                </span>
                <h6 class="mb-0">
                  {{ ticket.TicketBlock.TicketGroup.name }} •
                  {{ ticket.TicketBlock.name }}
                </h6>
                <small v-if="ticket.error || statusDic[ticket.status].message">
                  {{ ticket.error || statusDic[ticket.status].message }}
                </small>
              </div>
              <v-btn
                v-if="ticket.status === 'valid'"
                :loading="loadingConfirm === ticket.code"
                :disabled="!!loadingConfirm"
                @click="confirm(ticket)"
                class="mt-2"
                small
                depressed
                block
              >
                Validar
              </v-btn>
            </v-alert>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-btn :disabled="loading" @click="close()" block large text>
            Cancelar
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
    <audio id="success-beep" src="/beeps/success.mp3" preload="auto"></audio>
    <audio id="invalid-beep" src="/beeps/invalid.mp3" preload="auto"></audio>
  </v-dialog>
</template>

<script>
import SCANNER from "@/services/staff/scanner";
import validateCpf from "@/utils/validate-cpf";

export default {
  name: "ScannerFindByCPF",
  data: () => ({
    dialog: false,
    loading: false,
    loadingConfirm: false,
    error: null,
    type: "document",
    valid: false,
    searchValue: null,
    cpfRules: [
      (v) => !!v || "CPF é obrigatório",
      (v) => validateCpf(v) || "CPF inválido",
    ],

    tickets: null,
    statusDic: {
      valid: {
        text: "Válido",
        color: "info",
        icon: "mdi-ticket-confirmation",
      },
      used: {
        text: "Utilizado",
        message: "Ingresso já utilizado",
        color: "grey",
        icon: "mdi-ticket-outline",
      },
      validated: {
        text: "Validado",
        message: "Ingresso validado",
        color: "success",
        icon: "mdi-check",
      },
      error: {
        text: "Erro",
        message: "Erro ao validar ingresso",
        color: "warning",
        icon: "mdi-alert",
      },
    },
  }),
  methods: {
    beep(type) {
      const audio = document.getElementById(`${type}-beep`);
      if (audio) audio.play();
    },
    vibrate(pattern) {
      if (navigator.vibrate) navigator.vibrate(pattern);
    },
    async findByCpf() {
      try {
        this.loading = true;
        this.error = null;

        const { tickets } = await SCANNER.findByCPF(
          this.session.id,
          this.session.token,
          this.searchValue,
          this.type
        );

        if (tickets.length === 1) {
          this.$emit("scan", { value: tickets[0].code });
          return this.close(true);
        }

        this.tickets = this.sortTickets(tickets);
      } catch (error) {
        this.error = error.message || error;
        this.vibrate([200, 200]);
        this.beep("invalid");
      } finally {
        this.loading = false;
      }
    },
    open() {
      this.tickets = null;
      this.loading = false;
      this.error = null;
      this.searchValue = "";
      this.type = "document";
      this.dialog = true;
      this.processCode(code);
    },
    close(force = false) {
      if (!force && this.loading) return;
      this.tickets = null;
      this.dialog = false;
      this.searchValue = "";
    },
    sortTickets(tickets) {
      return tickets.sort((a, b) => {
        if (a.status !== b.status) return a.status === "valid" ? -1 : 1;
        return a.TicketBlock.TicketGroup.name.localeCompare(
          b.TicketBlock.TicketGroup.name
        );
      });
    },
    async confirm(ticket) {
      const index = this.tickets.findIndex((t) => t.code === ticket.code);
      try {
        this.loadingConfirm = ticket.code;
        this.tickets[index].error = null;

        await SCANNER.useTicket(
          this.session.id,
          this.session.token,
          ticket.code,
          true
        );

        this.tickets[index].status = "validated";
        this.vibrate(400);
        this.beep("success");
      } catch (e) {
        this.tickets[index].status = "error";
        this.tickets[index].error = e.message || e;
        this.vibrate([200, 200]);
        this.beep("invalid");
      } finally {
        this.loadingConfirm = false;
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.$emit("close");
    },
    valid(val) {
      if (!val) return;
      if (this.type === "document") {
        if (this.searchValue.length > 13) this.findByCpf();
      }
    },
    searchValue: {
      handler(value) {
        this.error = null;
        if (this.type === "id") {
          value = value
            .toUpperCase()
            .replace(/[^0-9A-F]/g, "")
            .slice(0, 8);

          this.$nextTick(() => {
            this.searchValue = value;
            if (this.searchValue.length === 8) this.findByCpf();
          });
        }
      },
      immediate: true,
    },
    type() {
      this.searchValue = "";
      this.error = null;
    },
  },

  props: {
    session: Object,
  },
  mounted() {
    this.$root.$on("find-by-cpf", this.open);
  },
};
</script>

<style></style>
