<template>
  <v-dialog v-model="dialog" width="380px">
    <v-card class="pa-4">
      <h6 class="text-center mb-4">
        {{ ticketBlock?.TicketGroup.name }} • {{ ticketBlock?.name }}
      </h6>
      <phone-input
        :phone="user.phone"
        @update:phone="(e) => (user.phone = e)"
        :ddi="user.ddi"
        @update:ddi="(e) => (user.ddi = e)"
        @valid="(v) => (valid = v)"
        dense
        outlined
        label="WhatsApp do Destinatário"
        :disabled="loading"
      />
      <v-alert color="warning" border="left" text dense>
        Atenção: ao emitir a cortesia, o processo é <b>irreversível</b> e a
        cortesia não poderá ser devolvida.
      </v-alert>
      <v-checkbox
        class="my-0"
        v-model="check"
        :disabled="loading"
        label="Confirmo que o WhatsApp do destinatário está correto."
        :rules="[(v) => !!v || 'Confirmação é obrigatória']"
        hide-details="auto"
      />

      <v-alert type="error" v-if="error" class="mt-2 mb-0">
        {{ error }}
      </v-alert>

      <div class="d-flex justify-space-between mt-4">
        <v-btn text @click="dialog = false" :disabled="loading"> Fechar </v-btn>
        <v-btn
          color="primary"
          @click="emit"
          :disabled="!valid || !check"
          :loading="loading"
        >
          Emitir
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import COURTESY_EMITTER from "@/services/staff/courtesyEmitter";
import PhoneInput from "../../global/PhoneInput.vue";

export default {
  components: { PhoneInput },
  props: {
    party: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      error: null,

      ticketBlock: null,
      valid: false,
      check: false,
      user: { phone: "", ddi: 55 },
    };
  },
  methods: {
    open(ticketBlock) {
      this.user = { phone: "", ddi: 55 };
      this.ticketBlock = ticketBlock;
      this.dialog = true;
    },
    async emit() {
      try {
        this.loading = true;
        this.error = null;

        await COURTESY_EMITTER.emit(this.party.id, this.ticketBlock.id, {
          phone: this.user.phone,
          ddi: this.user.ddi,
        });
        this.$emit("refresh");
        this.dialog = false;
      } catch (e) {
        this.error = e.message || "Erro ao emitir cortesia";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$parent.$on("courtesy-emitter", this.open);
  },
};
</script>

<style>
</style>