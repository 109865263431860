<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close()"
    scrollable
    :persistent="loading || !!ticket"
    max-width="400px"
    fullscreen
    content-class="modal-bg-dark"
  >
    <v-card
      :loading="loading"
      :style="modalBg"
      dark
      :color="error ? error.type : success ? 'success' : ''"
    >
      <v-card-text
        class="pb-1 d-flex gap-2 justify-space-around"
        style="overflow: hidden"
      >
        <v-card
          elevation="15"
          v-if="ticket?.TicketBlock?.TicketGroup?.braceletColor && success"
          class="h-full"
          style="width: 50px"
          :style="{
            backgroundColor: ticket.TicketBlock.TicketGroup.braceletColor,
          }"
        >
        </v-card>
        <div class="d-flex flex-column h-full justify-center flex-grow-1">
          <template v-if="!!ticket && !success">
            <div class="d-flex flex-column align-center gap-4 text-center">
              <base-avatar
                v-if="ticket.Owner?.photo"
                :seed="ticket.Owner.id"
                :size="150"
                :src="ticket.Owner.photo"
                class="mb-1"
              />
              <v-icon v-else size="120">mdi-account</v-icon>
              <v-chip v-if="membership">
                {{ membership.name }}
              </v-chip>
              <div>
                <h6 class="text-overline font-weight-black lh-1 mb-1">
                  {{ ticket.TicketBlock?.TicketGroup.name }}
                </h6>
                <h6 class="text-overline font-weight-bold lh-1 mb-0">
                  {{ ticket.TicketBlock?.name }}
                </h6>
              </div>
              <div v-if="ticket.Table">
                <h6 class="text-overline font-weight-black lh-1 mb-1">
                  {{ ticket.Table?.Group.name }} • {{ ticket.Table?.name }}
                </h6>
              </div>
              <div>
                <h4 class="mb-0">{{ ticket.Owner?.name }}</h4>
                <h5>{{ ticket.Owner?.document }}</h5>
              </div>

              <!-- Last Ticket Entry -->
              <v-alert
                dense
                v-if="ticket.TicketEntry?.length"
                type="warning"
                text
              >
                Tentativa de entrada recusada
                <b class="">
                  {{ [ticket.TicketEntry[0].createdAt] | dateDiff(true) }}
                  {{
                    ticket.TicketEntry[0].RegisterBy
                      ? "por " + ticket.TicketEntry[0].RegisterBy.name
                      : ""
                  }}
                  {{
                    ticket.TicketEntry[0].Session
                      ? "em " + ticket.TicketEntry[0].Session.name
                      : ""
                  }}
                </b>
              </v-alert>
            </div>

            <!-- Details -->
            <div v-if="!details">
              <v-btn @click="details = true" text small block>
                <v-icon>mdi-chevron-down</v-icon>
                Detalhes
              </v-btn>
            </div>
            <template v-else>
              <v-divider class="mb-4" />
              <p class="mb-0 text-center" v-if="ticket?.Payment?.PosSession">
                <b>
                  {{
                    ticket?.Payment?.PosSession?.partyId ? "Bilheteria" : "PDV"
                  }}:</b
                >
                {{
                  ticket.Payment?.PosSession?.name ||
                  ticket.Payment?.PosSession?.Address?.name
                }}
              </p>
              <p class="mb-0 text-center" v-else>
                <b>Vendedor:</b> {{ ticket.Seller?.name || "Loja Online" }}
              </p>
              <p class="mb-0 text-center">
                <b>Meio de Pagamento:</b>
                {{ paymentMethods[ticket.Payment.paymentMethod].text }}
              </p>
              <p class="mb-0 text-center">
                <b>Forma de Pagamento:</b>
                {{ paymentTypes[ticket.Payment.paymentType].text }}
              </p>
            </template>
          </template>

          <!-- Success -->
          <template v-else-if="!!ticket">
            <div
              class="d-flex flex-column align-center justify-center gap-x-4 gap-y-2 text-center"
            >
              <v-icon v-if="!tableMap" class="" size="75">mdi-ticket</v-icon>
              <div>
                <h3 class="mb-0 font-weight-black text-center">
                  Entrada permitida
                </h3>
                <p class="text-center mb-0">
                  em {{ success.createdAt | date("DD/MM/YY HH:mm") }}
                </p>

                <v-alert
                  v-if="ticket?.TicketBlock?.TicketGroup?.braceletColor"
                  class="mt-14 rounded-xl"
                  color="white"
                >
                  <div class="d-flex gap-3 align-center">
                    <v-icon color="black">mdi-arrow-left-bold</v-icon>
                    <span class="black--text font-weight-bold"
                      >Coloque uma pulseira desta cor
                    </span>
                  </div>
                </v-alert>
              </div>
            </div>
            <v-card
              v-if="tableMap"
              outlined
              class="mt-3 rounded-lg"
              style="max-width: 600px"
            >
              <table-selector
                :tableMap="tableMap"
                :party="session.Party"
                hideLabels
                :tooltip="formatTooltip"
                fixedTooltip
                :height="heightSize"
              />
            </v-card>
            <h5 v-if="tableMap" class="text-center mb-0 mt-2">
              {{ ticket.Table.Group.name }} • {{ ticket.Table.name }}
            </h5>
          </template>

          <div v-if="error">
            <h3 class="text-center">{{ error.message }}</h3>
            <v-alert v-if="error.period" dense text class="text-center">
              Período em andamento:
              <b>
                {{ error.period.name || `Periodo ${error.period.index + 1}` }}
              </b>
            </v-alert>
            <h6 class="text-center" v-if="error.entry?.RegisterBy">
              Registado por:
              {{ error.entry?.RegisterBy?.name }}
              {{ [error.entry.createdAt] | dateDiff(true) }}
            </h6>
            <h6 class="text-center" v-if="error.entry?.Session">
              Registado em: {{ error.entry.Session.name }} •
              {{ error.entry.scannerName }}
              {{ [error.entry.createdAt] | dateDiff(true) }}
            </h6>
          </div>
        </div>
      </v-card-text>

      <v-card-actions v-if="error || success">
        <div class="w-full">
          <v-progress-linear
            v-if="timeoutDelay"
            color="white"
            height="30px"
            class="rounded success--text"
            :value="(timeoutDelay / speed) * 100"
          >
            Fechando em
            {{ parseInt((timeoutDelay + 300) / 1000) }}s
          </v-progress-linear>
          <v-btn block text x-large @click="close(true)">Fechar</v-btn>
        </div>
      </v-card-actions>

      <v-card-actions v-if="!!ticket && !success">
        <v-row align="center" dense class="mx-0">
          <v-col cols="12" v-if="period">
            <v-alert type="info" text class="mb-2">
              Validando para o período
              <b>{{ period.name || period.index + 1 }}</b>
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="session.approvalCondition">
            <v-alert type="warning" class="mb-2">
              {{ session.approvalCondition }}
            </v-alert>
          </v-col>
          <v-col cols="auto" class="mb-2">
            <v-btn
              x-large
              icon
              block
              @click="close(true)"
              :disabled="loadingConfirm"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="" class="mb-2">
            <v-btn
              color="error"
              block
              @click="reject"
              :disabled="loadingConfirm"
              x-large
              height="78px"
            >
              Recusar
            </v-btn>
          </v-col>
          <v-col cols="" class="mb-2">
            <v-btn
              block
              color="primary"
              @click="confirm"
              :loading="loadingConfirm"
              x-large
              height="78px"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <audio id="success-beep" src="/beeps/success.mp3" preload="auto"></audio>
      <audio id="invalid-beep" src="/beeps/invalid.mp3" preload="auto"></audio>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";
import SCANNER from "@/services/staff/scanner";
import TableSelector from "@/components/global/partyTable/TableSelector.vue";

export default {
  name: "ScannerEntry",
  components: { TableSelector },
  data: () => ({
    dialog: false,
    loading: false,
    loadingConfirm: false,
    error: null,
    errorDetails: null,

    // Ticket Info
    code: null,
    ticket: null,
    tableMap: null,
    period: null,
    membership: null,

    details: false,
    success: false,

    timeout: null,
    timeoutDelay: null,

    // Dictionary
    paymentTypes: PAYMENT.paymentType,
    paymentMethods: PAYMENT.paymentMethod,
  }),
  methods: {
    vibrate(val) {
      if (navigator.vibrate) navigator.vibrate(val);
    },
    beep(type) {
      const audio = document.getElementById(`${type}-beep`);
      if (audio) audio.play();
    },
    reject() {
      try {
        this.$emit("finish", {
          ticket: this.ticket,
          approved: false,
        });
        this.save(false);
        this.beep("invalid");
        this.vibrate([200, 200]);
        this.close(true);
      } catch (e) {
        console.log(e);
      }
    },

    async confirm() {
      try {
        if (this.loadingConfirm) return;
        this.loadingConfirm = true;
        this.error = null;

        const response = await this.save(true);
        this.success = response.entry;
        if (response.ticket) this.ticket = response.ticket;
        this.$nextTick(() => {
          this.formattedTable();
        });
        if (
          !this.ticket?.Table &&
          !this.ticket?.TicketBlock?.TicketGroup?.braceletColor
        )
          this.closeTimeout(this.speed);
        this.vibrate(400);
        this.beep("success");
      } catch (error) {
        this.error = {
          message: error.message || "Ocorreu um erro inesperado",
          entry: error.error?.entry,
          type: error.error?.type || "error",
          period: error.error?.period,
        };
        this.vibrate([200, 200]);
        this.beep("invalid");
        this.$emit("finish");
      } finally {
        this.loadingConfirm = false;
      }
    },

    async save(approved) {
      this.error = null;

      return await SCANNER.useTicket(
        this.session.id,
        this.session.token,
        this.code,
        approved
      );
    },

    closeTimeout(timeout) {
      if (this.timeout) clearTimeout(this.timeout);
      if (this.timeoutDelay === null) this.timeoutDelay = timeout || 5000;
      else if (this.timeoutDelay <= 0) {
        clearTimeout(this.timeout);
        return this.close(true);
      }
      const tick = 100;
      this.timeout = setTimeout(() => {
        this.timeoutDelay -= tick;
        this.closeTimeout();
      }, tick);
    },

    async getTicketDetails() {
      try {
        this.vibrate(100);
        this.loading = true;
        this.error = null;
        if (!this.session.approvalCondition) {
          this.confirm();
          return;
        }

        const { ticket, period, membership } = await SCANNER.getTicket(
          this.session.id,
          this.session.token,
          this.code
        );
        this.ticket = ticket;
        this.period = period;
        this.membership = membership;
      } catch (error) {
        this.error = {
          message: error.message || "Ocorreu um erro inesperado",
          entry: error.error?.entry,
          type: error.error?.type || "error",
          period: error.error?.period,
        };
        this.vibrate([200, 200]);
        this.beep("invalid");
        this.$emit("finish");
      } finally {
        this.loading = false;
      }
    },
    processCode(code) {
      const uuidRegex =
        "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";

      const isUUID = new RegExp(`^${uuidRegex}$`).test(code);
      if (isUUID) {
        this.code = code;
        return this.getTicketDetails();
      }
      const ticketMatch = new RegExp(
        `\/v(erify)?\/t(icket)?\/(${uuidRegex}|[0-9]+)$`
      ).exec(code);
      if (ticketMatch) {
        this.code = ticketMatch[3];
        return this.getTicketDetails();
      }

      // const membership = /m(embership)?\/(\d+)/;
      // if (membership.test(code)) {
      //   this.error = {
      //     message:
      //       "Ops, esse código é de um cartão de sócio, não de um ingresso",
      //   };
      //   return;
      // }

      this.code = code;
      return this.getTicketDetails();

      this.error = { message: "Código inválido" };
      this.vibrate([200, 200]);
      return this.$emit("finish");
    },
    open(code) {
      this.loadingConfirm = false;
      this.details = false;
      this.error = null;
      this.ticket = null;
      this.period = null;
      this.membership = null;
      this.success = false;
      this.tableMap = null;
      this.dialog = true;

      clearTimeout(this.timeout);
      this.timeout = null;
      this.timeoutDelay = null;

      this.processCode(code);
    },
    close(force = false) {
      if (!force && (this.loading || this.loadingConfirm || !!this.ticket))
        return;

      if (this.success)
        this.$emit("finish", {
          ticket: this.ticket,
          approved: true,
        });

      this.dialog = false;
      this.code = null;
      this.ticket = null;
      this.period = null;
      clearTimeout(this.timeout);
      this.timeout = null;
      this.timeoutDelay = null;
    },
    formattedTable() {
      if (!this.ticket || !this.ticket.Table) return null;
      var { Table: tempTable } = this.ticket;
      if (!tempTable) return null;
      var { Group: tempGroup, ...Table } = tempTable;

      var { Map, ...Group } = tempGroup;
      const data = {
        ...Map,
        Groups: [
          {
            ...Group,
            Tables: [
              {
                ...Table,
              },
            ],
          },
        ],
      };

      this.tableMap = data;
    },
    formatTooltip(table, group) {
      return `<p class="mb-0 text-center">Mesa do Cliente<br><b class="font-weight-bold">${group.name} • ${table.name}</b></p>`;
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    modalBg() {
      if (!this.ticket || this.loading || !this.membership) return null;
      return {
        background: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) 80%, rgba(0, 0, 0, 0.6) 100%), url(${
          this.membership.backgroundImg
        }), ${this.membership.backgroundColor || "#3333"}80`,
        backgroundSize: "cover",
      };
    },
    speed() {
      return Math.max(this.session?.speed || 4000, 1000);
    },
    heightSize() {
      return window.innerHeight - 210;
    },
  },
  mounted() {
    this.$root.$on("entry-confirm", this.open);
  },
  destroyed() {
    this.$root.$off("entry-confirm", this.open);
  },
  props: {
    session: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
.modal-bg-dark {
  background-color: #1e1e1e !important;
}
</style>
