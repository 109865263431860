var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"380px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4 rounded-xl"},[_c('h6',{staticClass:"text-center mb-4"},[_vm._v(" "+_vm._s(_vm.ticketBlock?.TicketGroup.name)+" • "+_vm._s(_vm.ticketBlock?.name)+" ")]),(_vm.step === 1)?_c('div',[_c('v-textarea',{attrs:{"outlined":"","label":"WhatsApp (um por linha)","rules":[
          (v) => !!v || 'Informe pelo menos um telefone',
          (v) =>
            _vm.numberPhones.length > 0 ||
            'Informe pelo menos um telefone válido',
          (v) =>
            _vm.numberPhones.length <= _vm.remainingTickets ||
            'Você não possui ingressos suficientes',
        ],"messages":[
          (_vm.numberPhones.length > _vm.remainingTickets
            ? 'Você não possui ingressos suficientes. '
            : '') +
            `${_vm.numberPhones.length} telefone${
              _vm.numberPhones.length != 1 ? 's' : ''
            } de ${_vm.ticketBlockMessage}`,
        ]},model:{value:(_vm.user.phones),callback:function ($$v) {_vm.$set(_vm.user, "phones", $$v)},expression:"user.phones"}}),_c('v-alert',{attrs:{"color":"warning","border":"left","text":"","dense":""}},[_vm._v(" Atenção: ao emitir a cortesia, o processo é "),_c('b',[_vm._v("irreversível")]),_vm._v(" e a cortesia não poderá ser devolvida. ")]),_c('v-checkbox',{staticClass:"my-0",attrs:{"disabled":_vm.loading,"label":"Confirmo que o WhatsApp do destinatário está correto.","rules":[(v) => !!v || 'Confirmação é obrigatória'],"hide-details":"auto"},model:{value:(_vm.check),callback:function ($$v) {_vm.check=$$v},expression:"check"}}),(_vm.error)?_c('v-alert',{staticClass:"mt-2 mb-0",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading ||
            _vm.numberPhones.length === 0 ||
            !_vm.check ||
            _vm.numberPhones.length > _vm.remainingTickets},on:{"click":_vm.emit}},[_vm._v(" Emitir ")])],1)],1):(_vm.step === 2)?_c('div',{staticClass:"d-flex flex-column gap-4 text-center justify-center"},[(!_vm.allSent)?[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":100,"width":15,"value":(_vm.sendValue / _vm.totalValue) * 100,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.sendValue)+" / "+_vm._s(_vm.totalValue)+" ")])],1),_c('b',{attrs:{"block":""}},[_vm._v("Enviando Cortesias...")])]:[_c('v-icon',{attrs:{"size":"100","color":"green"}},[_vm._v("mdi-check-circle")]),_c('b',[_vm._v("Todas as cortesias foram enviadas")])],_c('v-btn',{staticClass:"mt-1",attrs:{"color":"primary","text":"","dense":"","disabled":_vm.loading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Fechar")])],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }