<template>
  <v-dialog v-model="dialog" width="380px">
    <v-card class="pa-4 rounded-xl">
      <h6 class="text-center mb-4">
        {{ ticketBlock?.TicketGroup.name }} • {{ ticketBlock?.name }}
      </h6>
      <div v-if="step === 1">
        <!-- Primeiro step -->
        <v-textarea
          v-model="user.phones"
          outlined
          label="WhatsApp (um por linha)"
          :rules="[
            (v) => !!v || 'Informe pelo menos um telefone',
            (v) =>
              numberPhones.length > 0 ||
              'Informe pelo menos um telefone válido',
            (v) =>
              numberPhones.length <= remainingTickets ||
              'Você não possui ingressos suficientes',
          ]"
          :messages="[
            (numberPhones.length > remainingTickets
              ? 'Você não possui ingressos suficientes. '
              : '') +
              `${numberPhones.length} telefone${
                numberPhones.length != 1 ? 's' : ''
              } de ${ticketBlockMessage}`,
          ]"
        />
        <v-alert color="warning" border="left" text dense>
          Atenção: ao emitir a cortesia, o processo é <b>irreversível</b> e a
          cortesia não poderá ser devolvida.
        </v-alert>
        <v-checkbox
          class="my-0"
          v-model="check"
          :disabled="loading"
          label="Confirmo que o WhatsApp do destinatário está correto."
          :rules="[(v) => !!v || 'Confirmação é obrigatória']"
          hide-details="auto"
        />
        <v-alert type="error" v-if="error" class="mt-2 mb-0">
          {{ error }}
        </v-alert>
        <div class="d-flex justify-space-between mt-4">
          <v-btn text @click="dialog = false" :disabled="loading">
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            @click="emit"
            :loading="loading"
            :disabled="
              loading ||
              numberPhones.length === 0 ||
              !check ||
              numberPhones.length > remainingTickets
            "
          >
            Emitir
          </v-btn>
        </div>
      </div>
      <div v-else-if="step === 2" class="d-flex flex-column gap-4 text-center justify-center">
        <template v-if="!allSent">
          <div class="text-center">
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="(sendValue / totalValue) * 100"
            color="primary"
          >
            {{ sendValue }} / {{ totalValue }}
          </v-progress-circular>
        </div>
          <b block>Enviando Cortesias...</b>
        </template>
        <template v-else>
          <v-icon size="100" color="green">mdi-check-circle</v-icon>
          <b>Todas as cortesias foram enviadas</b>
        </template>
        <v-btn
          color="primary"
          text
          dense
          class="mt-1"
          @click="dialog = false"
          :disabled="loading"
          >Fechar</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import COURTESY_EMITTER from "@/services/staff/courtesyEmitter";

export default {
  props: {
    party: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      error: null,
      courtesyEmitter: null,
      ticketBlock: null,
      check: false,
      user: { phones: "", ddi: 55 },
      step: 1,
      sendValue: 0,
      totalValue: 0,
      allSent: false,
    };
  },
  methods: {
    open(courtesyEmitter) {
      this.user = { phones: "", ddi: 55 };
      this.courtesyEmitter = courtesyEmitter;
      this.ticketBlock = courtesyEmitter.TicketBlock;
      this.dialog = true;
      this.step = 1;
      this.sendValue = 0;
      this.totalValue = 0;
      this.allSent = false;
      this.error = null;
      this.check = false;
    },
    async emit() {
      try {
        this.loading = true;
        this.error = null;

        const validPhones = this.numberPhones;

        if (validPhones.length === 0) {
          throw new Error("Nenhum telefone válido informado");
        }

        this.step = 2;
        this.sendValue = 0;
        this.totalValue = validPhones.length;
        this.allSent = false;

        for (const phone of validPhones) {
          await COURTESY_EMITTER.emit(this.party.id, this.ticketBlock.id, {
            phone,
            ddi: this.user.ddi,
          });
          this.sendValue++;
        }

        this.allSent = true;
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao emitir cortesia";
        this.step = 1;
      } finally {
        this.loading = false;
      }
    },
    formatPhones(value) {
      var phones = value.split("\n").map((phone) => phone.trim());
      phones = phones.map((phone) => this.phoneMask(phone));
      this.user.phones = phones.join("\n");
    },
    phoneMask(value) {
      const stringValue = value.toString();
      return stringValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d{4})/, "$1-$2")
        .substring(0, 15);
    },
    phoneIsValid(value) {
      return value.match(/[(][0-9]{2}[)] [0-9]{5}-[0-9]{4}/);
    },
  },
  watch: {
    "user.phones": {
      handler(value) {
        this.formatPhones(value);
      },
      immediate: true,
    },
  },
  mounted() {
    this.$parent.$on("courtesy-emitter-multiple", this.open);
  },
  computed: {
    remainingTickets() {
      if (!this.courtesyEmitter) return 0;
      return (
        this.courtesyEmitter.quantity - this.courtesyEmitter.Tickets.length
      );
    },
    numberPhones() {
      return this.user.phones.split("\n").filter((a) => this.phoneIsValid(a));
    },
    ticketBlockMessage() {
      if (!this.courtesyEmitter) return null;
      const remaining = this.remainingTickets;
      return `${remaining} ingresso${remaining != 1 ? "s" : ""} disponíve${
        remaining != 1 ? "is" : "l"
      }`;
    },
  },
};
</script>

<style></style>
